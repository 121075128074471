"use strict"

/**
 * @typedef {{[x: string]: boolean|number|{[x: string]: boolean}}} limits
 */

/**
 * @typedef {{[x: string]: boolean|number|"INF"|{[x: string]: boolean}}} storedLimits
 */

/**
 * @typedef {"INF" | boolean | number | {[x: string]: boolean}} jsonLimitValue
 */

class ServiceLimitSupport {
    /**
     *
     * @param {storedLimits} stored_limits
     * @returns {limits}
     */
    static realLimits(stored_limits) {
        /** @type {limits} */
       const limits_out = {}
       for(let k in stored_limits) {
            limits_out[k] = this.realLimitValue(stored_limits[k])
       }
       return limits_out
    }
    /**
     *
     * @param {jsonLimitValue} limit_value
     * @returns {boolean | number | {[x: string]: boolean}}
     */
    static realLimitValue(limit_value) {
        if(limit_value == "INF") {
            return Infinity
        } else {
            return limit_value
        }
    }
    /**
     *
     * @param {limits} real_limits
     * @returns {storedLimits}
     */
    static storedLimits(real_limits) {
        /** @type {storedLimits} */
        const limits_out = {}
        for(let k in real_limits) {
            if(real_limits[k] == Infinity) {
                limits_out[k] = "INF"
            } else {
                limits_out[k] = real_limits[k]
            }
        }
        return limits_out
    }
}